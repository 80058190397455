import React, {ReactNode} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {UserRolesEnum} from '../models-management/types';
import {useAuth} from './core/Auth';
import useRealUserRole from '../app/core/useCheckRole';
import {useBranding} from '../app/core/BrandingProvider';

interface Props {
  children: ReactNode;
  code: string;
  redirect?: boolean;
  showForAdmin?: boolean;
}
const HasNoPermission = ({children, code: permissionCode, showForAdmin}: Props) => {
  const {currentUser} = useAuth();
  const {organization} = useBranding();
  const location = useLocation();

  if (currentUser?.is_super_admin && showForAdmin) return <>{children}</>;

  if (!currentUser || !organization) return <></>;

  if (currentUser?.is_super_admin) return <></>;

  const orgPermission = organization.permissions?.find((d) => d.code === permissionCode);
  if (!orgPermission) return <>{children}</>; // does not has it

  if (currentUser?.is_org_admin) return <></>;

  const userPermission = currentUser.role?.permissions.find((d) => d.code === permissionCode);
  if (!userPermission) return <>{children}</>; // does not has it

  return <></>;
};

export default HasNoPermission;
