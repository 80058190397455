import React, {useEffect, useState} from 'react';
import EditorFields from './components/EditorFields/EditorFields';
import EditorViewer from './components/EditorViewer/EditorViewer';
import {Model} from '../models-management/types';
import clsx from 'clsx';
import useEditorStore from './model-editor.store';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {getSingleModel} from '../models-management/api/models-api';
import {toast} from 'react-toastify';
import {getModelFile, parseError} from '../../../shared/utils';
import {LoadingPlaceholder} from '../../../shared/components/LoadingPlaceholder';
import {useAuth} from '../auth';

interface Props {
  model?: Model;
  path?: string;
  onSave?: () => void;
  onClose?: () => void;
}

const SAMPLE_MODEL_URL = '/media/assets/glbs/sampleModel.glb';

const ModelEditor = ({model: passedModel, path, onSave}: Props) => {
  const [showEditor, setShowEditor] = useState(true);
  const [showViewer, setShowViewer] = useState(false);
  const [loading, setLoading] = useState(false);
  const {reset, setModel, model, fields, selectedTab, setSelectedTab, updateFields, groupRef} =
    useEditorStore();
  const [modelFile, setModelFile] = useState('');
  const auth = useAuth();

  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();

  const getGLB = async () => {
    if (!model?.location) return;
    try {
      const updatedGlpFile = await getModelFile(model?.location, auth.auth?.api_token);
      setModelFile(updatedGlpFile);
    } catch (error) {}
  };

  useEffect(() => {
    const get = async () => {
      if (!id) return;
      setLoading(true);
      try {
        const _model = await getSingleModel(id);
        setModel(_model);
      } catch (error) {
        toast.error(parseError(error));
      } finally {
        setLoading(false);
      }
    };

    get();
  }, [id]);

  useEffect(() => {
    passedModel && setModel(passedModel);
  }, [passedModel]);

  useEffect(() => {
    if (!model) return;

    getGLB();
  }, [model]);

  useEffect(() => {
    // this delay to insure window is painted before viewer get paint
    setTimeout(() => {
      setShowViewer(true);
    }, 500);

    return () => reset();
  }, []);

  if (loading) return <LoadingPlaceholder />;

  return (
    <div className='d-flex flex-column h-100'>
      <header className='align-items-center justify-content-between'>
        <div className='fs-3'>
          Edit Model:
          {model?.model_name}
        </div>
        {/* <div className='fs-4'>{model?.model_name}</div> */}
        <div></div>
      </header>

      <section className='flex-grow-1 '>
        <div className='w-100'>
          {/* mobile only button */}
          <button
            className='d-md-none btn btn-primary mb-2 mx-auto'
            onClick={() => setShowEditor((v) => !v)}
          >
            Toggle Editor
          </button>
        </div>

        <div className='d-flex h-100vh h-md-100 flex-column flex-md-row w-100'>
          <div className='flex-grow-1 order-1 order-md-0'>
            {showViewer && <EditorViewer path={path || modelFile} />}
          </div>
          <div
            className={clsx('w-full order-0 order-md-1 w-md-300px w-lg-400px ', {
              'd-none d-md-block': !showEditor,
            })}
          >
            <EditorFields
              onSave={() => {
                navigate(`/dashboard/models`);
                onSave && onSave();
              }}
            />
          </div>
        </div>
      </section>

      {/* <footer>
        <button className='btn btn-primary'>Back</button>
      </footer> */}
    </div>
  );
};

export default ModelEditor;
