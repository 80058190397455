/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {getSharedModel as RealRequest, getModelsCategories} from '../../api/models-api';
import {getSharedModel as FakeRequest} from '../../api/models-api.fake';
import dayjs from 'dayjs';
import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {AppLocator} from '../../../../../app-locator';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {isObjectEmpty, parseError, setFavIcon} from '../../../../../shared/utils';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import Viewer3D from '../../../model-viewer/components/Viewer3D';
import VTOGlasses from '../../../model-viewer/components/VTOGlasses';
import {ModelSharingType, Model, ModelCategoryEnum, Category} from '../../types';
import {useThemeMode} from '../../../../../_metronic/partials';
import {useBranding} from '../../../app/core/BrandingProvider';
import {useAuth} from '../../../auth';
import {getOrganizationByDomain} from '../../../admin/api/organizations-api';
import {WatchesApp} from '../ar-model/components/WatchesApp';
import {toast} from 'react-toastify';
import {usePermissionChecker} from '../../../admin/pages/roles/hooks/permission-checker';

const productionMode = process.env.NODE_ENV === 'production';
const getModel = AppLocator.forceUsingRealApi || productionMode ? RealRequest : FakeRequest;
const APP_URL = productionMode
  ? `https://${window.location.hostname}`
  : `http://${window.location.hostname}:3011`;
interface Props {
  type?: ModelSharingType;
}
export const SharedModelPage = ({type}: Props) => {
  const [search] = useSearchParams();
  const {mode} = useThemeMode();

  const [model, setModel] = useState<Model>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [shareType, setShareType] = useState(type);
  const {organization, setOrganization} = useBranding();
  const [categories, setCategories] = useState<Category[]>([]);
  const {hasPermission} = usePermissionChecker();

  const id = search.get('id');
  const location = search.get('location');
  const is_sample = search.get('is_sample') === '1';
  const name = search.get('name');
  const arlink = is_sample
    ? `${APP_URL}/models/ar-app?id=${id}&is_external=1&location=${location}&name=${name}`
    : `${APP_URL}/models/ar-app?id=${id}&is_external=1`;

  useEffect(() => {
    const getBranding = async () => {
      const org = await getOrganizationByDomain(window.location.hostname);
      setLoading(false);

      if (!org || isObjectEmpty(org)) return;

      setOrganization(org);
    };

    getBranding();
  }, []);

  useEffect(() => {
    if (!organization) {
      document.title = 'VRee AI';
      return;
    }
    document.title = organization.window_title || 'VRee AI';

    if (!organization.favicon) return;
    setFavIcon(organization.favicon);
  }, [organization]);

  useEffect(() => {
    if (!id || is_sample) return;

    setLoading(true);

    const get = async () => {
      try {
        const model = await getModel(id);
        setModel(model);
      } catch (error) {
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    };

    get();
  }, [id]);

  useEffect(() => {
    const get = async () => {
      try {
        const categories = await getModelsCategories();
        setCategories(categories);
      } catch (error) {
        toast.error('Loading categories failed');
        console.error(error);
      }
    };

    if (hasPermission('models.category')) get();
  }, []);

  if (loading) return <LoadingPlaceholder label='loading model' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            `/media/illustrations/sketchy-1/14${mode === 'dark' ? '-dark' : ''}.png`
          )})`,
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 text-center'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
              className='theme-dark-show h-75px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
              className='theme-light-show h-75px'
            ></img>
          </a>
          {/* end::Logo */}

          {/* begin::Wrapper */}
          <section className='w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 pt-4 mx-auto'>
            <h3 className='fw-bolder mb-3 capitalized'>
              View Shared Model: {model?.model_name || name}
            </h3>

            <div className='mw-980px mx-auto mb-7 position-relative'>
              {shareType !== 'ar' && model && (
                <Viewer3D
                  model_path={is_sample ? `${location}` : `${model?.location}?type=3D`}
                  shared_model={model?.is_shared}
                  model={model}
                />
              )}
              {shareType === 'ar' && model?.category_name !== 'Watches' && (
                <VTOGlasses model_path={`${model?.location}?type=AR`} />
              )}
              {shareType === 'ar' && model?.category_name === 'Watches' && (
                <WatchesApp
                  model={model}
                  parameters={{
                    // id: "",
                    is_external: true,
                    // is_sample: ,
                    location: model?.location,
                    // query
                  }}
                />
              )}
            </div>

            <div className='mt-8 text-muted mx-lg-15 mx-5'>
              This model is shared by link, to create a new model like this one, you can use VRee
              app to convert any image to 3D model
              {!!categories.find((c) => c.id === model?.category_id)?.has_ar && (
                <span>and use it inside your website or AR app</span>
              )}
            </div>

            {shareType !== 'ar' && !!categories.find((c) => c.id === model?.category_id)?.has_ar && (
              <div className='mt-6'>
                <button
                  className='btn btn-primary rounded-pill'
                  onClick={() => {
                    window.open(
                      arlink,
                      ``,
                      `height=${screen.availHeight * 0.8},width=${
                        (screen.availHeight * 0.8) / (16 / 9)
                      },toolbar=no,directories=no,status=no, linemenubar=no,scrollbars=no,resizable=yes ,modal=yes`
                    );
                  }}
                >
                  Try it on
                </button>
              </div>
            )}

            <div className='mt-8'>
              <div className='d-flex flex-center text-start fw-bold'>
                <div className='text-gray-400  me-3 w-100px '>Category:</div>
                <div className=' fs-6 capitalized w-200px'>{model?.category_name}</div>
              </div>

              <div className='d-flex flex-center text-start fw-bold'>
                <div className='text-gray-400  me-3 w-100px '>Creation Date:</div>
                <div className=' fs-6 w-200px'>
                  {dayjs(model?.created_date).format('DD MMM, YYYY - HH:mm ')}
                </div>
              </div>
            </div>
          </section>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}

        {/* begin::Footer */}
        <footer className='d-none flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-semibold fs-6'>
            <a
              href={organization?.about_link || 'https://vree.ai/#section_clients'}
              className='text-muted text-hover-primary px-2'
            >
              About
            </a>

            <a
              href={organization?.contact_link || 'https://vree.ai/#section_contact'}
              className='text-muted text-hover-primary px-2'
            >
              Contact Us
            </a>
          </div>
        </footer>
        {/* end::Footer */}
      </div>
    </>
  );
};
