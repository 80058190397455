/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react';
import clsx from 'clsx';
import {SceneImage, SceneImageStatusEnum} from '../../../../media.types';
import useMediaWizardStore from '../../media-wizard.store';
import {useAuth} from '../../../../../auth';
import {Skeleton} from 'primereact/skeleton';
import {regenerateScenes} from '../../../../media.api';
import {toast} from 'react-toastify';
import {parseError} from '../../../../../../../shared/utils';
import {useNavigate} from 'react-router-dom';
import {useBranding} from '../../../../../app/core/BrandingProvider';

interface Props {
  sceneImage?: SceneImage;
  onRegeneratingClicked?: () => void;
}

const SceneImageCard = ({sceneImage, onRegeneratingClicked}: Props) => {
  const {selectedScene, setSelectedScene, media: video} = useMediaWizardStore();
  const selected = selectedScene?.pub_id === sceneImage?.pub_id;
  const {currentUser} = useAuth();
  const width = video.resolution.split('x')[0];
  const height = video.resolution.split('x')[1];
  const orientation = +width > +height ? 'landscape' : 'portrait';
  const [regenerating, setRegenerating] = useState(false);
  const navigate = useNavigate();
  const {outOfCredits, setOutOfCredits} = useBranding();

  const regenerate = async () => {
    setRegenerating(true);

    try {
      const res = await regenerateScenes(video.pub_id, sceneImage?.pub_id);
      setRegenerating(false);

      onRegeneratingClicked && onRegeneratingClicked();
    } catch (error) {
      toast.error(`Regeneration failed, Details: (${parseError(error)})`);
    }
  };

  if (!sceneImage) return <div>not ready!</div>;

  return (
    <div className='px-5'>
      <div className='hover-container'>
        <div
          className={clsx('rounded-3', {
            'border-2 border-primary shadow-primary ': selected,
          })}
          onClick={() => {
            sceneImage.status === SceneImageStatusEnum.COMPLETED && setSelectedScene(sceneImage);
          }}
        >
          {sceneImage.status === SceneImageStatusEnum.COMPLETED && (
            <img className='rounded w-100' src={sceneImage.image_location} alt='scene' />
          )}
          {sceneImage.status !== SceneImageStatusEnum.COMPLETED && (
            <Skeleton
              className='rounded min-w-200px'
              width={orientation === 'portrait' ? '100%' : '100%'}
              height={orientation === 'portrait' ? '35vh' : '260px'}
            />
          )}

          {selected && (
            <div className='position-absolute top-0 right-0  p-2 '>
              <a
                className=' position-relative p-1 rounded-circle bg-primary  d-flex flex-center align-items-center'
                onClick={(e) => {}}
              >
                <i className='ki-solid ki-check-circle text-white  fs-2'></i>
              </a>
            </div>
          )}
        </div>
        {currentUser?.is_super_admin && (
          <div className='text-muted mt-2'>{sceneImage.scene?.description}</div>
        )}

        <div className='visible-on-hover mt-2 text-center'>
          {sceneImage.status === SceneImageStatusEnum.COMPLETED && !!video.is_auto_descriped && (
            <div
              className='w-100 text-center mb-0'
              title={outOfCredits ? 'Cannot generate more media, credits are used up' : ''}
            >
              <button
                className={clsx('btn btn-info btn-sm', {
                  'opacity-50 ': outOfCredits,
                })}
                onClick={() => {
                  if (outOfCredits) return;
                  regenerate();
                }}
              >
                {!regenerating && <span className='d-flex align-items-center'>More like this</span>}
                {regenerating && (
                  <span className='indicator-progress ' style={{display: 'block'}}>
                    Please Wait ...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SceneImageCard;
