/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {Video, VideoStatusEnum} from '../../../vidoes.types';
import dayjs from 'dayjs';
import {convertSecondsToReadableTime} from '../../../../../../shared/date-util';
import clsx from 'clsx';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import VideoDialog from './VideoDialog';
import {Dialog} from 'primereact/dialog';
import './VideoCard.scss';
import TemplateForm from '../../Templates/components/TemplateForm';
import {useAuth} from '../../../../auth';
import {Link, useNavigate} from 'react-router-dom';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import {downloadVideo, getRandomString, parseError} from '../../../../../../shared/utils';
import {confirmDialog} from 'primereact/confirmdialog';
import {toast} from 'react-toastify';
import {ModalLayout} from '../../../../shared/components/ModalLayout';
import {deleteDialogProps} from '../../../../shared/types';
import {deleteTemplate, deleteVideo} from '../../../vidoes.api';
import VideoSharingWindow from './VideoSharingWindow';
import {useCardsSelection} from '../../../../models-management/pages/models-list/core/CardsSelectionProvider';
import HasPermission from '../../../../auth/HasPermission';
dayjs.extend(relativeTime as any);

const PROCESSING_MSGS = [
  'A stunning video is being generated, stunning just like you are! It may take few minutes',
  'Your video is being made, go grab a drink, it may take few minutes.',
  'Your video is being generated, we promise it will be sooo good but it may take few minutes.',
  `Your video is in the oven, baking to perfection! It'll be ready in a few minutes, just like a delicious pizza!`,
  `Your video is being sprinkled with awesomeness. It may take a few minutes, but good things come to those who wait!`,
  `We're brewing your video like a fine cup of coffee. It'll be done in a few minutes, hold on tight!`,
  `Patience, young grasshopper! Your video is being forged in the fires of creativity. Just a few more minutes!`,
  `Your video is getting its groove on! It’ll be ready in a few minutes, so feel free to dance while you wait!`,
  `Hold on, we're adding some extra sparkle to your video! It might take a few minutes, but shiny things take time!`,
  `Your video is being assembled by tiny, very diligent video elves. They need a few more minutes!`,
  `Your video is getting a virtual makeover! It’ll be done in a few minutes, just like your favorite reality show!`,
  `Your video is marinating in awesomeness. Give it a few minutes to soak up all the goodness!`,
  `Your video is being crafted with extra love and care. It might take a few minutes, but it’ll be worth the wait!`,
  `Hold tight! Your amazing video is cooking up. It might take a few minutes, but it's worth the wait!`,
  `Great things take time! Your video is on its way. Feel free to stretch your legs for a few minutes.`,
  `Your masterpiece is in the works! It might take a few minutes, so why not take a quick break?`,
  `Magic is happening! Your video is being crafted and will be ready in a few minutes.`,
  `Good things come to those who wait! Your video is being created and will be ready shortly.`,
  `Your video is baking in the creative oven. It'll be ready in a few minutes, just hang tight!`,
  `The wheels are turning! Your video is on the way. It might take a few minutes, but it'll be awesome!`,
  `Your video is being woven together. It may take a few minutes, but it will be worth every second!`,
  `Excitement building! Your video is coming together. Just a few more minutes of patience, please.`,
  `Your cinematic experience is being crafted. It may take a few minutes, so sit back and relax!`,
];

interface Props {
  video: Video;
  isFocused?: boolean;
  isTemplate?: boolean;
  onUpdate?: () => void;
}
const VideoCard = ({video, isFocused, isTemplate, onUpdate}: Props) => {
  const {
    id,
    pub_id,
    name,
    modelId,
    thumbnail,
    length,
    create_date: createDate,
    number_of_models,
    video: videoPath,
    experience_name,
    status,
    reason,
    created_by,
    published,
  } = video;
  const [showVideo, setShowVideo] = useState(false);
  const [showWindow, setShowWindow] = useState(false);
  const [showEditWindow, setShowEditWindow] = useState(false);
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [showShareWindow, setShowShareWindow] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const {select, unSelect, checkSelected} = useCardsSelection();
  const selected = checkSelected(pub_id + '');

  const deleteBtnClicked = () => {
    confirmDialog({
      ...deleteDialogProps,
      message: `Are you sure you want to delete?`,
      accept: () => {
        submitDelete();
      },
      reject: () => {},
    });
  };

  const submitDelete = async () => {
    if (!video) return;

    try {
      setDeleting(true);

      if (isTemplate) await deleteTemplate(video.pub_id);
      else await deleteVideo(video.pub_id);

      toast.success('Record deleted successfully!');

      onUpdate && onUpdate();
    } catch (error) {
      setDeleting(false);
      alert(parseError(error));
    }
    setDeleting(false);
  };

  const download = async () => {
    if (!video.video) return;

    try {
      await downloadVideo({videoUrl: video.video, fileName: video.name, onProgress});
    } catch (error: any) {
      toast.error('Download failed, please contact support!');
    }
  };

  const onProgress = (progressEvent: ProgressEvent) => {
    const total = progressEvent.total ?? 0;
    const current = progressEvent.loaded;
    const percentCompleted = Math.round((current / total) * 100);
    setDownloadProgress(percentCompleted);
  };

  return (
    <article
      onBlur={() => setShowVideo(false)}
      className={clsx('card card-bordered card-flush shadow-sm', {
        'border-primary border-1 shadow-primary shadow-sm': selected,
      })}
    >
      <header className='position-absolute top-0 left-0 right-0 z-4 d-flex justify-content-between align-items-center gradient-background p-4 py-4 rounded-top text-white'>
        <div>
          <div className=' fs-4 fw-semibold capitalized '>
            {name || experience_name || '-No Name-'}
          </div>

          {!showVideo && (
            <div className='d-flex align-items-center'>
              {dayjs(createDate).format('D-MM-YY, H:mm')}
              {currentUser?.is_super_admin && !isTemplate && (
                <div className='ms-3'>
                  By:
                  <a
                    className='btn btn-link btn-flush text-active-primary  fw-normal ms-1  text-white text-capitalize'
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/account/${created_by?.id}`);
                    }}
                  >
                    {created_by?.name}
                  </a>
                </div>
              )}
            </div>
          )}

          {/* {!isTemplate && (
            <div>
              {status === VideoStatusEnum.FAILED && (
                <span className='opacity-75 badge badge-light-danger' title={reason}>
                  Failed
                </span>
              )}
              {status === VideoStatusEnum.PENDING && (
                <span className='opacity-75 badge badge-light-warning'>Pending</span>
              )}
              {status === VideoStatusEnum.PROCESSING && (
                <span className='opacity-75 badge badge-light-info'>Processing</span>
              )}
            </div>
          )} */}
        </div>
        <div className='d-flex'>
          {false && !showVideo && (
            <a
              className='cursor-pointer w-30px h-30px rounded-circle hover-bg-black  d-flex flex-center'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <i className='ki-outline ki-dots-vertical text-white fs-1'></i>
            </a>
          )}

          {showVideo && (
            <a
              title='Hide Video'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center z-far'
              onClick={(e) => {
                e.stopPropagation();
                setShowVideo(false);
              }}
            >
              <i className='ki-outline ki-cross-square text-white fs-1'></i>
            </a>
          )}

          {!selected && (
            <a
              title='Select'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center'
              onClick={(e) => {
                e.stopPropagation();
                select(pub_id + '');
              }}
            >
              <i className='ki-outline ki-mouse-square text-white fs-1'></i>
            </a>
          )}

          {selected && (
            <a
              title='Unselect'
              className='cursor-pointer w-30px h-30px rounded-circle bg-hover-white  d-flex flex-center'
              onClick={(e) => {
                e.stopPropagation();
                unSelect(pub_id + '');
              }}
            >
              <i className='ki-solid ki-check-square text-primary fs-1'></i>
            </a>
          )}
        </div>
      </header>

      {!showVideo && (
        <div
          onClick={() => (status === VideoStatusEnum.COMPLETED || isTemplate) && setShowVideo(true)}
          className='video-thumbnail-container aspect-ratio-box min-h-400px bg-light  rounded'
          style={{backgroundImage: `url('${thumbnail}')`, backgroundSize: 'cover'}}
        >
          <div>
            {(status === VideoStatusEnum.COMPLETED || isTemplate) && (
              <div className='play-icon bg-danger'>
                {/* <i className='bi bi-play-fill fs-4 text-white'></i> */}
                <i className='ki-outline ki-to-right fs-1 text-white'></i>
              </div>
            )}

            {status === VideoStatusEnum.PROCESSING && (
              <>
                <div className='play-icon bg-info'>
                  <i className='fa  fa-spinner fa-spin fs-4 text-white'></i>{' '}
                  {/* Bootstrap play icon */}
                </div>
                <div className='text-info text-sm text-center mt-15 center-center w-100 px-2  '>
                  {getRandomString(PROCESSING_MSGS)}
                </div>
              </>
            )}

            {status === VideoStatusEnum.FAILED && (
              <div className='play-icon bg-warning'>
                <i className='fa fa-exclamation-triangle fs-4 text-white'></i>{' '}
                {/* Bootstrap play icon */}
              </div>
            )}
          </div>
        </div>
      )}

      {showVideo && (
        <div className='aspect-ratio-box-16-9 position-absolute top-0 z-5'>
          <video
            id={id + ''}
            // src={toAbsoluteUrl(videoPath || '')}
            //   src={toAbsoluteUrl('/media/videos/placeholder-video.mp4')}
            poster={toAbsoluteUrl(thumbnail)}
            // poster='https://placehold.co/600x330'
            className='w-100 rounded  aspect-ratio-box-content '
            autoPlay
            controls
            controlsList='nodownload'
            muted
            // onPause={() => setShowVideo((v) => !v)}
            onEnded={() => setShowVideo((v) => !v)}
            onBlur={() => setShowVideo(false)}
          >
            {/* <source src={toAbsoluteUrl(`/media/dummy_video.mp4`)} type='video/mp4' /> */}
            <source src={toAbsoluteUrl(videoPath || '')} type='video/mp4' />
          </video>
        </div>
      )}

      {!showVideo && (
        <footer className='position-absolute bottom-0 left-0 right-0 z-3 d-flex justify-content-between align-items-center gradient-background-reverse p-4 py-4 rounded-bottom text-white'>
          <div className=' '>
            {isTemplate && (
              <HasPermission code='templates.publish' extraCondition={isTemplate}>
                <div>
                  {!published && <span className=' badge badge-light-light '>Draft</span>}
                  {published && (
                    <div className='d-flex align-items-center  badge badge-light-light'>
                      <i className='ki-outline ki-verify  text-white fs-2 me-1'></i>
                      Published
                    </div>
                  )}
                </div>
              </HasPermission>
            )}
            {!isTemplate && (
              <div>
                {status === VideoStatusEnum.FAILED && (
                  <span className=' badge badge-light-danger' title={reason}>
                    Failed
                  </span>
                )}
                {status === VideoStatusEnum.PENDING && (
                  <span className=' badge badge-light-warning'>Pending</span>
                )}
                {status === VideoStatusEnum.PROCESSING && (
                  <span className=' badge badge-light-info'>Processing</span>
                )}
                {/* {status === VideoStatusEnum.COMPLETED && (
                  <span className=' badge badge-success '>Completed</span>
                )} */}
              </div>
            )}
          </div>
          <div className='d-flex align-items-center'>
            {downloadProgress > 0 && downloadProgress < 100 && (
              <div className=''>({downloadProgress}%)</div>
            )}

            <HasPermission code='templates.edit' extraCondition={isTemplate}>
              <a
                title='Edit'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-info  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditWindow(true);
                }}
              >
                <i className='ki-outline ki-notepad-edit text-white fs-2'></i>
              </a>
            </HasPermission>

            {!isTemplate && video.status === VideoStatusEnum.COMPLETED && (
              <a
                title='Download'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-info  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  download();
                }}
              >
                {!(downloadProgress > 0 && downloadProgress < 100) && (
                  <i className='ki-outline ki-cloud-download text-white fs-2'></i>
                )}
                {downloadProgress > 0 && downloadProgress < 100 && (
                  <div className='position-relative'>
                    <i className='ki-outline ki-loading text-white fa fa-spin fs-2'></i>
                  </div>
                )}
              </a>
            )}

            {!isTemplate && video.status === VideoStatusEnum.COMPLETED && (
              <a
                title='Share'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-primary  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowShareWindow(true);
                }}
              >
                <i className='bi bi-share text-white fs-3'></i>
                {/* <i className='ki-outline ki-share text-white fs-2'></i> */}
              </a>
            )}

            <HasPermission code='templates.delete'>
              <a
                title='Delete'
                className='cursor-pointer w-30px h-30px rounded-circle bg-hover-danger  d-flex flex-center my-1'
                onClick={(e) => {
                  e.stopPropagation();
                  deleteBtnClicked();
                }}
              >
                {!deleting && <i className='ki-outline ki-trash text-white fs-2'></i>}
                {deleting && <i className='fa fa-spin fa-refresh text-danger fs-2'></i>}
              </a>
            </HasPermission>
          </div>
        </footer>
      )}

      <div className='d-none card-body'>
        <div className='d-flex justify-content-between align-items-center mb-1'>
          <h2
            className='fs-4 pulse fw-bold pulse-primary  btn btn-link btn-flush d-flex flex-row cursor-pointer text-active-primary text-left'
            style={{width: 'calc(100% - 70px)'}}
            onClick={() => !isTemplate && setShowWindow(true)}
          >
            <span className='w-100 text-truncate'>{name || experience_name || '-No Name-'}</span>
            {isFocused && <span className='pulse-ring border-3 float-end'></span>}
          </h2>

          {isTemplate && currentUser?.is_super_admin && (
            <div>
              <button
                className='btn btn-light-primary btn-sm'
                onClick={() => setShowEditWindow(true)}
              >
                <span>Edit</span>
              </button>
            </div>
          )}
          {!isTemplate && (
            <div>
              {status === VideoStatusEnum.FAILED && (
                <span className='badge badge-light-danger' title={reason}>
                  Failed
                </span>
              )}
              {status === VideoStatusEnum.PENDING && (
                <span className='badge badge-light-warning'>Pending</span>
              )}
              {status === VideoStatusEnum.PROCESSING && (
                <span className='badge badge-light-info'>Processing</span>
              )}
              {status === VideoStatusEnum.COMPLETED && (
                <span className='badge badge-light-success'>Completed</span>
              )}
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between'>
          {isTemplate && (
            <p className='card-text mb-0 fs-5' title='Models Required to create the template'>
              {number_of_models} Model
            </p>
          )}
          <div className='card-text '>
            {currentUser?.is_super_admin && (
              <Link
                className='btn btn-link btn-flush text-active-primary p-0 fw-normal fs-5'
                to={`/account/${created_by?.id}`}
              >
                {created_by?.name}
              </Link>
            )}

            <p className='card-text mb-0 fs-5'> {dayjs(createDate).format('D MMM YY, H:mm')}</p>
            {/* <p className='card-text mb-0 fs-5'> {dayjs(createDate).fromNow()}</p> */}
          </div>
        </div>
      </div>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showWindow}
        onHide={() => setShowWindow(false)}
      >
        <VideoDialog
          video={video}
          submitComplete={(ok: boolean) => {
            setShowWindow(false);
            ok && onUpdate && onUpdate();
          }}
        />
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showEditWindow}
        onHide={() => setShowEditWindow(false)}
      >
        <TemplateForm
          template={video}
          submitComplete={(ok) => {
            setShowEditWindow(false);
            ok && onUpdate && onUpdate();
          }}
        />
      </Dialog>

      <Dialog
        modal
        dismissableMask
        closable={false}
        visible={showShareWindow}
        onHide={() => setShowShareWindow(false)}
      >
        <ModalLayout
          onClose={() => setShowShareWindow(false)}
          showHeader
          showFooter
          showCloseButton
          title={`Share: ${video.name}`}
        >
          <VideoSharingWindow video={video} />
        </ModalLayout>
      </Dialog>
    </article>
  );
};

export default VideoCard;
