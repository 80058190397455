/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl';
import Authorize from '../../../../app/modules/auth/Authorize';
import {ModelStatusesEnum} from '../../../../app/modules/models-management/types';
import {AsideMenuItem} from './AsideMenuItem';
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub';
import {useBranding} from '../../../../app/modules/app/core/BrandingProvider';
import useRealUserRole from '../../../../app/modules/app/core/useCheckRole';
import {useAuth} from '../../../../app/modules/auth';
import HasPermission from '../../../../app/modules/auth/HasPermission';
import HasNoPermission from '../../../../app/modules/auth/HasNoPermission';
import {KTIcon, KTSVG} from '../../../helpers';
import clsx from 'clsx';
import {title} from 'process';
import {Link} from 'react-router-dom';
import {AsideMenuSolidItem} from './AsideMenuSolidItem';
import {usePermissionChecker} from '../../../../app/modules/admin/pages/roles/hooks/permission-checker';

const categoriesPermissions = [
  // 'category.anything',
  'category.eyewear',
  'category.footwear',
  'category.watches',
];

export const AsideMenuMain = () => {
  const {currentUser} = useAuth();
  const {hasAnyPermission, checkAllowedPermissions, hasNoPermission, hasPermission} =
    usePermissionChecker();
  const {outOfCredits} = useBranding();

  const allowedCategories = checkAllowedPermissions(categoriesPermissions);

  return (
    <>
      {/* Normal User Menu */}
      {!currentUser?.is_org_admin && !currentUser?.is_super_admin && (
        <>
          <HasPermission code='models.create'>
            <AsideMenuSolidItem
              to='/dashboard/create-model?fromMenu=1'
              icon='plus-circle'
              title='Generate 3D Model'
            />
          </HasPermission>

          <HasPermission code='medias.create'>
            <AsideMenuSolidItem
              to={`/rich-media/generate`}
              icon='youtube'
              title='Generate New Media'
              disableActiveStyle
              disabled={outOfCredits}
              placeholder={outOfCredits ? 'Cannot generate more medias, credits are used up' : ''}
            />
          </HasPermission>

          <HasPermission code='videos.create'>
            <AsideMenuSolidItem
              to={`/dashboard/models?from3DExp=1&status=${ModelStatusesEnum.COMPLETED}`}
              icon='youtube'
              title='Generate 3D Experience'
              disableActiveStyle
              // disabled={outOfCredits}
              // placeholder={outOfCredits ? 'Cannot generate more medias, credits are used up' : ''}
            />
          </HasPermission>

          <div className='mb-5'></div>

          <HasPermission code='menu.user-overview'>
            <AsideMenuItem to='/dashboard/overview' icon='abstract-28' title='Overview' />
          </HasPermission>

          <HasPermission code='menu.admin-overview'>
            <AsideMenuItem title='Admin Overview' to='/admin/overview' icon='abstract-28' />
          </HasPermission>

          <HasPermission code='menu.medias'>
            <AsideMenuItem to='/rich-media/list' icon='youtube' title='Media Library' />
          </HasPermission>

          <HasPermission code='menu.users'>
            <AsideMenuItem title='Users' to='admin/users' icon='profile-user' />
          </HasPermission>

          <HasPermission code='menu.login-activity'>
            <AsideMenuItem to='/admin/login-sessions' icon='key-square' title='Login Activity' />
          </HasPermission>

          <HasPermission code='menu.models'>
            <div className='menu-item pt-5'>
              <div className='menu-content'>
                <span className='menu-heading fw-bold text-uppercase fs-7'>3D Assets</span>
              </div>
            </div>

            <AsideMenuItem to='/dashboard/models' icon='abstract-28' title='My 3D Models' />

            <HasPermission code='menu.samples'>
              {allowedCategories.length > 1 && (
                <AsideMenuItemWithSub to='/dashboard/samples' icon='abstract-28' title='Samples'>
                  <HasPermission code='category.eyewear'>
                    <AsideMenuItem
                      to='/dashboard/samples/glasses'
                      icon='abstract-49'
                      title='Glasses'
                    />
                  </HasPermission>
                  <HasPermission code='category.watches'>
                    <AsideMenuItem
                      to='/dashboard/samples/watches'
                      icon='electronic-clock'
                      title='Watches'
                    />
                  </HasPermission>
                  <HasPermission code='category.footwear'>
                    <AsideMenuItem
                      to='/dashboard/samples/footwear'
                      icon='abstract-1'
                      title='Footwear'
                      extText='(coming soon)'
                    />
                  </HasPermission>
                </AsideMenuItemWithSub>
              )}

              {allowedCategories.length === 1 && (
                <>
                  <HasPermission code='category.eyewear'>
                    <AsideMenuItem
                      to='/dashboard/samples/glasses'
                      icon='abstract-49'
                      title='Sample Glasses'
                    />
                  </HasPermission>
                  <HasPermission code='category.watches'>
                    <AsideMenuItem
                      to='/dashboard/samples/watches'
                      icon='electronic-clock'
                      title='Sample Watches'
                    />
                  </HasPermission>
                  <HasPermission code='category.footwear'>
                    <AsideMenuItem
                      to='/dashboard/samples/footwear'
                      icon='abstract-1'
                      title='Sample Footwear'
                      extText='(coming soon)'
                    />
                  </HasPermission>
                </>
              )}
            </HasPermission>
          </HasPermission>

          <HasPermission code='menu.models-report'>
            <AsideMenuItem to='/reports/models' icon='graph-4' title='Models Report' />
          </HasPermission>

          <HasPermission code='menu.videos'>
            <div className='menu-item pt-5'>
              <div className='menu-content'>
                <span className='menu-heading fw-bold text-uppercase fs-7'>3D Experiences</span>
              </div>
            </div>

            {<AsideMenuItem to='/videos/my-videos' icon='youtube' title='My Experiences' />}

            <HasPermission code='menu.all-templates'>
              {<AsideMenuItem to='/videos/templates' icon='youtube' title='Templates' />}
            </HasPermission>

            <HasPermission code='menu.published-templates'>
              {<AsideMenuItem to='/videos/templates' icon='youtube' title='Templates' />}
            </HasPermission>
          </HasPermission>
        </>
      )}

      {/* Super Admin Menu */}
      {currentUser?.is_super_admin && (
        <>
          <HasPermission code='models.create'>
            <AsideMenuSolidItem
              to='/dashboard/create-model?fromMenu=1'
              icon='plus-circle'
              title='Generate 3D Model'
            />

            {/* <div className='mb-5'></div> */}
          </HasPermission>

          <HasPermission code='medias.create'>
            <AsideMenuSolidItem
              to={`/rich-media/generate`}
              icon='youtube'
              title='Generate New Media'
              disableActiveStyle
            />
          </HasPermission>

          <HasPermission code='videos.create'>
            <AsideMenuSolidItem
              to={`/dashboard/models?from3DExp=1&status=${ModelStatusesEnum.COMPLETED}`}
              icon='youtube'
              title='Generate 3D Experience'
              disableActiveStyle
              // disabled={outOfCredits}
              // placeholder={outOfCredits ? 'Cannot generate more medias, credits are used up' : ''}
            />
          </HasPermission>

          <div className='mb-5'></div>

          <AsideMenuItem to='/admin/overview' icon='shield' title='Admin Overview' />

          <HasPermission code='menu.medias'>
            <AsideMenuItem to='/rich-media/list' icon='youtube' title='Media Library' />
          </HasPermission>

          <div className='menu-item pt-5'>
            <div className='menu-content'>
              <span className='menu-heading fw-bold text-uppercase fs-7'>3D Assets</span>
            </div>
          </div>

          <AsideMenuItem to='/dashboard/models' icon='abstract-28' title='My 3D Models' />

          <AsideMenuItemWithSub to='/dashboard/samples' icon='abstract-28' title='Samples'>
            <AsideMenuItem to='/dashboard/samples/glasses' icon='abstract-49' title='Glasses' />
            <AsideMenuItem
              to='/dashboard/samples/watches'
              icon='electronic-clock'
              title='Watches'
            />
            <AsideMenuItem
              to='/dashboard/samples/footwear'
              icon='abstract-1'
              title='Footwear'
              extText='(coming soon)'
            />
          </AsideMenuItemWithSub>
          <AsideMenuItem to='/reports/models' icon='graph-4' title='Models Report' />

          <HasPermission code='menu.videos'>
            <>
              <div className='menu-item pt-5'>
                <div className='menu-content'>
                  <span className='menu-heading fw-bold text-uppercase fs-7'>3D Experiences</span>
                </div>
              </div>

              {<AsideMenuItem to='/videos/my-videos' icon='youtube' title='My Experiences' />}
              {<AsideMenuItem to='/videos/templates' icon='youtube' title='Templates' />}
            </>
          </HasPermission>

          <div className='menu-item pt-5'>
            <div className='menu-content'>
              <span className='menu-heading fw-bold text-uppercase fs-7'>Admin</span>
            </div>
          </div>

          <AsideMenuItem to='/admin/login-sessions' icon='key-square' title='Login Activity' />
          <AsideMenuItem to='/admin/users' icon='profile-user' title='Users' />
          <AsideMenuItem to='/admin/organizations' icon='office-bag' title='Organizations' />
          <AsideMenuItem to='/roles' icon='security-user' title='Roles' />
          <AsideMenuItem to='/admin/settings' icon='setting-3' title='Settings' />
          <AsideMenuItem to='/billing/packages' icon='office-bag' title='Packages' />
          <AsideMenuItem to='/billing/payments' icon='dollar' title='Payments' />
        </>
      )}

      {/* Org Admin Menu */}
      {currentUser?.is_org_admin && (
        <>
          <HasPermission code='models.create'>
            <AsideMenuSolidItem
              to='/dashboard/create-model?fromMenu=1'
              icon='plus-circle'
              title='Generate 3D Model'
            />
          </HasPermission>

          <HasPermission code='medias.create'>
            <AsideMenuSolidItem
              to={`/rich-media/generate`}
              icon='youtube'
              title='Generate New Media'
              disableActiveStyle
              disabled={outOfCredits}
              placeholder={outOfCredits ? 'Cannot generate more media, credits are used up' : ''}
            />
          </HasPermission>

          <HasPermission code='videos.create'>
            <AsideMenuSolidItem
              to={`/dashboard/models?from3DExp=1&status=${ModelStatusesEnum.COMPLETED}`}
              icon='youtube'
              title='Generate 3D Experience'
              disableActiveStyle
              // disabled={outOfCredits}
              // placeholder={outOfCredits ? 'Cannot generate more medias, credits are used up' : ''}
            />
          </HasPermission>

          <div className='mb-5'></div>

          <AsideMenuItem title='Admin Overview' to='/admin/overview' icon='abstract-28' />

          <HasPermission code='menu.medias'>
            <AsideMenuItem to='/rich-media/list' icon='youtube' title='Media Library' />
          </HasPermission>

          <HasPermission code='menu.models'>
            <div className='menu-item pt-5'>
              <div className='menu-content'>
                <span className='menu-heading fw-bold text-uppercase fs-7'>3D Assets</span>
              </div>
            </div>

            <AsideMenuItem to='/dashboard/models' icon='abstract-28' title='My 3D Models' />

            <HasPermission code='menu.samples'>
              <HasPermission code='models.category'>
                <AsideMenuItemWithSub to='/dashboard/samples' icon='abstract-28' title='Samples'>
                  <AsideMenuItem
                    to='/dashboard/samples/glasses'
                    icon='abstract-49'
                    title='Glasses'
                  />
                  <AsideMenuItem
                    to='/dashboard/samples/watches'
                    icon='electronic-clock'
                    title='Watches'
                  />
                  <AsideMenuItem
                    to='/dashboard/samples/footwear'
                    icon='abstract-1'
                    title='Footwear'
                    extText='(coming soon)'
                  />
                </AsideMenuItemWithSub>
              </HasPermission>

              <HasNoPermission code='models.category'>
                <AsideMenuItem
                  to='/dashboard/samples/glasses'
                  icon='abstract-28'
                  title='Sample Models'
                />
              </HasNoPermission>
            </HasPermission>

            <HasPermission code='menu.models-report'>
              <AsideMenuItem to='/reports/models' icon='graph-4' title='Models Report' />
            </HasPermission>
          </HasPermission>

          <HasPermission code='menu.videos'>
            <div className='menu-item pt-5'>
              <div className='menu-content'>
                <span className='menu-heading fw-bold text-uppercase fs-7'>3D Experiences</span>
              </div>
            </div>

            {<AsideMenuItem to='/videos/my-videos' icon='youtube' title='My Experiences' />}
          </HasPermission>

          <HasPermission code='menu.all-templates'>
            {<AsideMenuItem to='/videos/templates' icon='youtube' title='Templates' />}
          </HasPermission>

          <div className='menu-item pt-5'>
            <div className='menu-content'>
              <span className='menu-heading fw-bold text-uppercase fs-7'>Admin</span>
            </div>
          </div>

          <AsideMenuItem title='Users' to='admin/users' icon='profile-user' />

          <AsideMenuItem to='/roles' icon='security-user' title='Roles' />

          <HasPermission code='menu.login-activity'>
            <AsideMenuItem to='/admin/login-sessions' icon='key-square' title='Login Activity' />
          </HasPermission>
        </>
      )}
    </>
  );
};
