/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {getSharedModel as RealRequest} from '../../api/models-api';
import {getSharedModel as FakeRequest} from '../../api/models-api.fake';
import dayjs from 'dayjs';
import {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {AppLocator} from '../../../../../app-locator';
import {ErrorPlaceholder} from '../../../../../shared/components/ErrorPlaceholder';
import {LoadingPlaceholder} from '../../../../../shared/components/LoadingPlaceholder';
import {parseError} from '../../../../../shared/utils';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers';
import Viewer3D from '../../../model-viewer/components/Viewer3D';
import VTOGlasses from '../../../model-viewer/components/VTOGlasses';
import {ModelSharingType, Model} from '../../types';

const productionMode = process.env.NODE_ENV === 'production';
const getModel = AppLocator.forceUsingRealApi || productionMode ? RealRequest : FakeRequest;

export const EmbeddedModelPage = () => {
  const [search] = useSearchParams();

  const [model, setModel] = useState<Model>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const id = search.get('id');

  useEffect(() => {
    document?.body?.classList.add('modal-open');
  }, []);

  useEffect(() => {
    if (!id) return;

    setLoading(true);

    const get = async () => {
      try {
        const model = await getModel(id);
        setModel(model);
      } catch (error) {
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    };

    get();
  }, [id]);

  if (loading || !model) return <LoadingPlaceholder label='loading model' />;
  if (error) return <ErrorPlaceholder error={error} />;

  return (
    <>
      <div className='w-100 h-100 overflow-hidden'>
        <Viewer3D model_path={`${model?.location}?type=3D`} isEmbed={true} model={model} />
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/colorful-lavendar.svg')}
          onClick={() => window.open('https://vree.ai')}
          title='Made with ♥ by vree.ai'
          className='theme-light-show h-3rem position-absolute opacity-50 opacity-100-hover'
          style={{right: '10px', bottom: '10px'}}
        />
      </div>
    </>
  );
};
