import React, {useEffect, useRef, useState} from 'react';
import useMediaWizardStore from '../../media-wizard.store';
import {InputText} from 'primereact/inputtext';
import {RadioButton} from 'primereact/radiobutton';
import {CanvasEditor} from '../../../../../canvas-editor/CanvasEditor';
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers';
import {Checkbox} from 'primereact/checkbox';
import {Next} from 'react-bootstrap/esm/PageItem';
import {Skeleton} from 'primereact/skeleton';
import SceneImageCard from './SceneImage';
import {fetchImageAsFile, parseError, scrollToTop} from '../../../../../../../shared/utils';
import {SceneImage, SceneImageStatusEnum} from '../../../../media.types';
import {
  deleteMedia,
  generateMedia,
  getSingleMedia,
  regenerateScenes,
  selectSceneImage,
} from '../../../../media.api';
import clsx from 'clsx';
import {deleteDialogProps} from '../../../../../shared/types';
import {confirmDialog} from 'primereact/confirmdialog';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import TimedProgressbar from '../../../../../../../shared/components/TimedProgressbar';
import {getUserCreditInfo} from '../../../../../models-management/api/models-api';
import {useBranding} from '../../../../../app/core/BrandingProvider';

const sampleScenes: SceneImage[] = [
  {
    id: 1,
    pub_id: '1',
    image_location: '/media/videos/thumb/8.jpg',
    adjusted_image_location: '/media/videos/thumb/8.jpg',
    reason: '',
    status: 0,
  },
  // {
  //   id: 2,
  //   pub_id: '2',
  //   image_location: '/media/videos/thumb/2.jpg',
  //   adjusted_image_location: '/media/videos/thumb/2.jpg',
  // },
  // {
  //   id: 3,
  //   pub_id: '3',
  //   image_location: '/media/videos/thumb/3.jpg',
  //   adjusted_image_location: '/media/videos/thumb/3.jpg',
  // },
  // {
  //   id: 4,
  //   pub_id: '4',
  //   image_location: '/media/videos/thumb/4.jpg',
  //   adjusted_image_location: '/media/videos/thumb/4.jpg',
  // },
];

const SceneSelectionStep = () => {
  const {prev, next, media: video, selectedScene, updateMedia: updateVideo} = useMediaWizardStore();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [generated, setGenerated] = useState(false);
  const [sceneImages, setSceneImages] = useState<SceneImage[]>(video.scene_images || []);
  const intervalRef = useRef<number | null>(null); // Use useRef to store the interval ID
  const width = video.resolution.split('x')[0];
  const height = video.resolution.split('x')[1];
  const orientation = +width > +height ? 'landscape' : 'portrait';
  const square = width === height;
  const [deleting, setDeleting] = useState(false);
  const [regenerating, setRegenerating] = useState(false);
  const {id: pubId} = useParams<{id: string}>();
  const {outOfCredits, setOutOfCredits} = useBranding();
  const navigate = useNavigate();

  const submit = async () => {
    setError('');

    if (!selectedScene) {
      scrollToTop();
      setError('Please choose one scene to create the video');
      return;
    }

    try {
      next();
      scrollToTop();
    } catch (error) {
      setSubmitting(false);
    }
  };

  const back = () => {
    confirmDialog({
      ...deleteDialogProps,
      message: `Video will be discarded, are you sure?`,
      accept: () => {
        prev();

        submitDelete(); // optimistic update
      },
      reject: () => {},
    });
  };

  const submitDelete = async () => {
    if (!video) return;

    try {
      setDeleting(true);

      await deleteMedia(video.pub_id);

      toast.info('Video discarded!');

      setDeleting(false);

      window.history.pushState(null, '', `${window.location.origin}/rich-media/generate`); // remove id from route
    } catch (error) {
      setDeleting(false);
      toast.error(parseError(error));
    }
  };

  const get = async () => {
    setLoading(true);
    const res = await getSingleMedia(video.pub_id);
    setLoading(false);

    const i = res.video[0].scene_images;

    if (!i) return;
    setSceneImages(i);
  };

  const startInterval = () => {
    console.log('startInterval');
    if (intervalRef.current === null) {
      intervalRef.current = window.setInterval(() => {
        get();
      }, 3000);
    }
  };

  const stopInterval = () => {
    console.log('stopInterval');
    if (intervalRef.current !== null) {
      setGenerated(true);
      clearInterval(intervalRef.current);
      intervalRef.current = null; // Reset ref to avoid multiple intervals
    }
  };
  function checkPendingImages() {
    const pendingCount = sceneImages.filter(
      (d) => d.status === SceneImageStatusEnum.PENDING
    ).length;

    console.log('images set, pending:', pendingCount);
    return pendingCount;
  }

  const regenerate = async () => {
    setRegenerating(true);
    const res = await regenerateScenes(video.pub_id);
    setRegenerating(false);
    setGenerated(false);

    reRunInterval();
    checkCredit();
  };

  function reRunInterval() {
    setGenerated(false);
    get();
    startInterval();
  }
  const checkCredit = async () => {
    const credits = await getUserCreditInfo();
    setOutOfCredits(credits.usedCredits >= credits.maxCredits);
  };

  useEffect(() => {
    if (pubId) {
      const defaultGenerated = checkPendingImages() === 0;
      setGenerated(defaultGenerated);
    }

    get();
    startInterval();
    scrollToTop();

    return () => {
      stopInterval();
    };
  }, []);

  useEffect(() => {
    if (!sceneImages || sceneImages.length === 0) return;

    const pendingCount = checkPendingImages();

    if (pendingCount === 0) {
      stopInterval();
    }
  }, [sceneImages]);

  return (
    <div className='container w-100'>
      {error && <div className='alert alert-danger mb-5'>{error}</div>}

      {!generated && (
        <section className='mb-10'>
          <div className=''>
            <div>
              <h2 className='fw-bold d-flex align-items-center text-gray-900'>
                Generating your scenes
                {loading && <i className='fa fa-refresh fa-spin ms-4 text-primary'></i>}
              </h2>
              <div className=' fs-6 mb-10 text-muted'>
                We are generating your scenes, this process may take up to one minute{' '}
              </div>
            </div>

            <div className='text-center mw-500px w-100'>
              <TimedProgressbar time={20000} />
            </div>
          </div>

          {/* <div className='row'>
            {[1, 2, 3, 4].map((d) => (
              <div
                className={clsx(
                  {'col-md-6': square || orientation === 'landscape'},
                  {'col-md-3': orientation === 'portrait'}
                )}
              >
                <div className='w-100 p-3'>
                  <Skeleton width='100%' height='50vh' borderRadius='15px' />
                  <Skeleton
                    width='30%'
                    className='mt-2 mb-10 mx-auto'
                    borderRadius='16px'
                  ></Skeleton>
                </div>
              </div>
            ))}
          </div> */}
        </section>
      )}

      {generated && (
        <section className='mb-10'>
          <h2 className='fw-bold d-flex align-items-center text-gray-900'>Scenes Generated!</h2>
          <div className=' fs-6 mb-10 text-muted'>
            Choose one of the suggestions by our Co-Pilot.
          </div>
        </section>
      )}

      <div className='row g-5 mb-0'>
        {sceneImages?.map((sceneImage) => (
          <div
            className={clsx(
              ' justify-content-center d-flex',
              {'col-md-6': square || orientation === 'landscape'},
              {'col-md-3': orientation === 'portrait'}
            )}
          >
            <SceneImageCard
              sceneImage={sceneImage}
              onRegeneratingClicked={() => {
                setGenerated(false);
                reRunInterval();
                checkCredit();
              }}
            />
          </div>
        ))}
      </div>

      <footer className='d-flex justify-content-between mt-5'>
        {/* {!pubId && ( */}
        <button className='btn btn-light ' onClick={back}>
          <span className='d-flex align-items-center'>
            <i className='me-2 ki-duotone ki-arrow-left fs-3'>
              <span className='path1' />
              <span className='path2' />
            </i>
            Back
          </span>
        </button>
        {/* )} */}

        {/* {pubId && <div></div>} */}

        {generated && (
          <button
            title={outOfCredits ? 'Cannot generate more media, credits are used up' : ''}
            className={clsx('btn btn-primary ', {
              'opacity-50 ': outOfCredits,
            })}
            onClick={() => {
              if (outOfCredits) return;
              regenerate();
            }}
          >
            {!regenerating && (
              <span className='d-flex align-items-center'>
                Regenerate
                <i className='ms-2 ki-duotone ki-arrow-circle-right fs-3'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
              </span>
            )}
            {regenerating && (
              <span className='indicator-progress ' style={{display: 'block'}}>
                Please Wait ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        )}

        <button
          title={outOfCredits ? 'Cannot generate more media, credits are used up' : ''}
          className={clsx('btn btn-primary ', {
            'opacity-50 ': outOfCredits,
          })}
          onClick={() => {
            if (outOfCredits) return;
            submit();
          }}
        >
          {!submitting && (
            <span className='d-flex align-items-center'>
              Continue
              <i className='ms-2 ki-duotone ki-arrow-right fs-3'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          )}
          {submitting && (
            <span className='indicator-progress ' style={{display: 'block'}}>
              Please Wait ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </footer>
    </div>
  );
};

export default SceneSelectionStep;
