import {create} from 'zustand';
import {ModelEditorProps} from './model-editor.types';
import {Model} from '../models-management/types';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

interface Props {
  reset: () => void;

  model?: Model;
  setModel: (model: Model) => void;

  selectedTab: number;
  setSelectedTab: (id: number) => void;

  fields: ModelEditorProps;
  setFields: (fields: Partial<ModelEditorProps>) => void;

  updateFields: (updates: DeepPartial<ModelEditorProps>) => void;

  groupRef: THREE.Group | null;
  setGroupRef: (ref: THREE.Group) => void;
}

const initialFields = {
  lenses: {
    color: '#ffffff',
    metallic: 0.6,
    roughness: 0,
    transparency: 0.8,
  },
  frame: {
    tex: 'original',
    color: '',
    metallic: 0.2,
    roughness: 0.1,
    transparency: 0,
  },
  temple: {
    tex: 'original',
    color: '#ffffff',
    metallic: 0.1,
    roughness: 0.2,
    transparency: 0,
  },
};
const useEditorStore = create<Props>((set, get) => ({
  model: undefined,
  selectedTab: 0,
  fields: initialFields,

  setModel: (model) => set({model: model}),
  setSelectedTab: (id) => set({selectedTab: id}),
  setFields: (data) => {
    set({fields: {...get().fields, ...data}});
  },

  updateFields: (updates) => {
    const original = get().fields;
    const updated = {
      fields: {
        ...original,
        ...updates,
        frame: {
          ...original.frame,
          ...updates.frame,
        },
        lenses: {
          ...original.lenses,
          ...updates.lenses,
        },
        temple: {
          ...original.temple,
          ...updates.temple,
        },
      },
    };
    console.log('Fields updated', updated);
    set(updated);
  },

  groupRef: null, // Initially, the groupRef is null
  setGroupRef: (ref) => set({groupRef: ref}), // Function to update the groupRef

  reset: () => {
    set({
      model: undefined,
      selectedTab: 0,
      fields: initialFields,
      groupRef: null, // Initially, the groupRef is null
    });
  },
}));

export default useEditorStore;
