import {MediaCard} from './components/MediaCard';
import {PageTitle} from '../../../../../_metronic/layout/core';
import {KTSVG} from '../../../../../_metronic/helpers';
import {Link, useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import {MediaLibraryQueryProvider, useMediaLibraryQuery} from './MediaLibraryQueryProvider';
import useMediaLibraryStore from './media-library.store';
import {EmptyPlaceholder} from '../../../../../shared/components/EmptyPlaceholder';
import {MediaPagination} from './components/MediaPagination';
import {useEffect, useRef} from 'react';
import {ModelStatusesNamesEnum} from '../../../models-management/types';
import {MediaStatusEnum} from '../../media.types';
import {
  CardsSelectionProvider,
  useCardsSelection,
} from '../../../models-management/pages/models-list/core/CardsSelectionProvider';
import {MediaSelectionOptions} from './components/MediaSelectionOptions';
import {useBranding} from '../../../app/core/BrandingProvider';
import {useQueryClient} from 'react-query';
import BlockUi from 'react-block-ui';

const MediaLibrary = () => {
  const {setVideos, reset, filters, updateFilters} = useMediaLibraryStore();
  const {isLoading, refetch, response} = useMediaLibraryQuery();
  const {outOfCredits} = useBranding();
  const navigate = useNavigate();
  const {count, selectedCards, selectRange, clearAll} = useCardsSelection();
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <div className='h-100'>
      <PageTitle breadcrumbs={[]}>Media Library</PageTitle>

      <div className='app-container '>
        <header className='d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex align-items-center flex-grow-1'>
            <h2>Your Completed Media ({response?.count || 0})</h2>
            <div className='ms-3 d-flex align-items-center flex-grow-1'>
              {/* <VideosSearch /> */}
              <button
                className={clsx(
                  'btn btn-sm btn-icon btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold ms-2'
                )}
                onClick={async () => {
                  // await queryClient.invalidateQueries(); // Clears the cache for this key
                  refetch();
                }}
                title='Refresh models and reset cache'
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr029.svg'
                    className={clsx('svg-icon-muted svg-icon-4', {
                      'fa fa-spin': isLoading,
                    })}
                  />
                </span>
              </button>

              {count > 0 && <MediaSelectionOptions />}
            </div>
          </div>
          <div className='tools d-flex'>
            <ul className='nav nav-pills me-6 mb-2 mb-sm-0'>
              <li className='nav-item m-0'>{/* <VideoSorting /> */}</li>
              <li className='nav-item m-0'>{/* <VideosFilter /> */}</li>
            </ul>

            <button
              title={
                count > 0
                  ? 'Open video editor and import the selected media'
                  : 'Please select media items to start editing'
              }
              className={clsx('btn btn-sm  btn-primary fw-bold mx-2  position-relative ', {
                'opacity-50 ': count === 0,
              })}
              onClick={() => {
                if (count === 0) return;

                navigate(`/rich-media/editor/${selectedCards.join(',')}`);
              }}
            >
              <i className='ki-outline ki-notepad-edit me-2'></i>
              Import to Editor
            </button>

            <button
              onClick={() => {
                if (outOfCredits) return;
                navigate(`/rich-media/generate`);
              }}
              className={clsx('btn btn-primary btn-sm fw-bold ms-2 ', {
                'opacity-50 ': outOfCredits,
              })}
              title={outOfCredits ? 'Cannot generate more media, credits are used up' : ''}
            >
              Generate New Media
            </button>
          </div>
        </header>

        <div className='position-relative'>
          <BlockUi tag='div' blocking={isLoading} message='Loading media ...' keepInView>
            <div className='min-h-600px'>
              {response?.data?.length === 0 && (
                <EmptyPlaceholder
                  label='No media found!'
                  description='Try generating a new media or search with different keywords'
                />
              )}

              <div className='row g-5'>
                {response?.data?.map((media) => (
                  <div key={media.pub_id} className='col-sm-4 col-lg-3'>
                    <MediaCard video={media} />
                  </div>
                ))}
              </div>
            </div>
          </BlockUi>
        </div>

        <MediaPagination />
      </div>
    </div>
  );
};

export const MediaLibraryPage = () => {
  return (
    <MediaLibraryQueryProvider>
      <CardsSelectionProvider>
        <MediaLibrary />
      </CardsSelectionProvider>
    </MediaLibraryQueryProvider>
  );
};

export default MediaLibrary;
