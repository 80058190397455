import {create} from 'zustand';
import {Model} from '../../../models-management/types';
import {Media, SceneImage} from '../../media.types';

interface IStore {
  reset: () => void;

  currentStep: number;
  next: () => void;
  prev: () => void;
  setCurrentStep: (step: number) => void;

  media: Media;
  setMedia: (media: Media) => void;
  updateMedia: (mediaParts: Partial<Media>) => void;

  selectedScene?: SceneImage;
  setSelectedScene: (sceneImage?: SceneImage) => void;
}

const initialMedia: Media = {
  id: 0,
  pub_id: '',
  name: '',
  prompt: '',
  create_date: new Date(),
  created_by: {
    id: 0,
    name: '',
  },
  status: 0,
  thumbnail: '',
  video_url: '',
  width: 0,
  height: 0,
  resolution: '',
  enhancePrompt: true,
  is_auto_descriped: 1,
  owner_user: {
    id: 0,
    name: '',
  },
  dynamics_description: '',
};
const useMediaWizardStore = create<IStore>((set, get) => ({
  currentStep: 1,

  next: () => {
    set({currentStep: get().currentStep + 1});
  },

  prev: () => {
    set({currentStep: get().currentStep - 1});
  },

  setCurrentStep: (step) => {
    set({currentStep: step});
  },

  media: initialMedia,
  setMedia: (video) => {
    set({media: video});
  },

  updateMedia: (videoPart) => {
    const updatedVideo = {...get().media, ...videoPart};
    set({media: updatedVideo});
    console.log('media updated', updatedVideo);
  },

  selectedScene: undefined,
  setSelectedScene: (image) => {
    set({selectedScene: image});
  },

  reset: () => {
    set({
      currentStep: 1,
      media: initialMedia,
    });
  },
}));

export default useMediaWizardStore;
